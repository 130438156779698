.my_table table thead {
  background-color: #eee;
}

.my_table table td, .my_table table th {
  padding: 5px 10px;
  font-size: 14px;
  font-family: Verdana;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.my_table table {
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

/* .my-tabs .ant-tabs-tab {
  width: 210px;
}

.my-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  overflow: hidden;
  text-overflow: ellipsis;
} */

.upfile {
   padding-bottom: 5px;
}