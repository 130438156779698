.site-tag-plus {
    background: #fff;
    border-style: dashed;
    margin-bottom: 5px;
}

.edit-tag {
    user-select: none;
    margin-bottom: 5px;
}

.tag-input {
    width: 78px;
    margin-right: 8px;
    vertical-align: top;
}

[data-theme="dark"] .site-tag-plus {
    background: transparent;
    border-style: dashed;
}