* {
	padding: 0;
	margin: 0;
}

.m-Hmain {
	width: 100%;
	box-sizing: border-box;
	padding: 30px 30px;
	background: #fff;
}

.m-Hmain-div {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
}

.m-Hmain-pie {
	width: 467px;
	height: 364px;
	background: rgba(255, 255, 255, 1);
	border: 1px solid rgba(202, 202, 202, 0.61);
	border-radius: 10px;
	padding: 18px 28px;
	margin-bottom: 14px;
}

.m-Hmain-title {
	width: 100%;
	line-height: 23px;
	font-size: 22px;
	font-weight: bold;
	color: rgba(0, 0, 0, 1);
	padding-bottom: 18px;
	border-bottom: 1px solid rgba(202, 202, 202, 0.61);
	margin-bottom: 14px;
}

.m-Hmain-pic {
	font-size: 25px;
	font-weight: 400;
	color: rgba(133, 133, 133, 1);
	line-height: 24px;
}

.m-Hmain-pic2 {
	line-height: 19px;
	font-size: 19px;
	font-weight: 400;
	display: flex;
	align-items: center;
	color: rgba(133, 133, 133, 1);
}

.m-Hmain-flex {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 178px;
	margin-bottom: 25px;
}

.m-flex2 {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.m-flex2 img {
	width: 16px;
	height: 16px;
}

.m-Hmain-imgT {
	width: 100%;
	line-height: 20px;
	display: flex;
	align-items: center;
}

.m-Hmain-imgT div {
	margin-left: 13px;
	display: flex;
}

.m-Hmain-imgT div span:nth-child(1) {
	line-height: 19px;
	font-size: 19px;
	font-weight: 400;
	color: rgba(133, 133, 133, 1);
}

.m-Hmain-imgT img {
	width: 16px;
	height: 16px;
}

.m-smallbtn {
	width: 48px;
	line-height: 21px;
	background: rgba(91, 168, 255, 1);
	border-radius: 3px;
	text-align: center;
	font-size: 17px;
	color: #fff;
	margin-right: 6px;
}

.m-Hmain-text {
	line-height: 20px;
	font-size: 26px;
	font-weight: bold;
	color: rgba(0, 0, 0, 1);
}
