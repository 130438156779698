.container {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

.top {
    width: 100%;
}

.box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.zhuanpan {
    width: 85%;
}

.choujiang {
    width: 20%;
    position: absolute;
}

.wodejiangpin {
    margin-left: 5%;
}

.goods_box {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    height: 320px;
    text-align: center;
    box-sizing: border-box;
    background: #fff;
    padding: 30px;
    border-radius: 30px;
    border: #fff solid 1px;
}

.title {
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 30px;
}

.close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 15px;
    height: 15px;
}

.list {
    overflow: auto;
    height: 200px;
}

.list::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
    scrollbar-arrow-color: red;
}

.list::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
    scrollbar-arrow-color: red;
}

.list::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}

.mark {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
}

.goods_name {
    margin-bottom: 5px;
}

.result {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    height: 320px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}

.result_box {
    background-image: url(../img/bg1.png);
    background-size: 241px 259px;
    background-repeat: no-repeat;
    width: 241px;
    height: 259px;
    position: relative;
}

.result_title {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
}

.result_text {
    position: absolute;
    top: 110px;
    left: 44px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.notice {
    position: absolute;
    top: 183px;
    font-size: 10px;
    color: #fff;
    width: 100%;
}

.expiration_date {
    font-size: 12px;
    position: absolute;
    top: 150px;
    left: 108px;
}

.introduce {
    position: absolute;
    top: 94px;
    left: 94px;
    width: 96px;
    height: 44px;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-self: center;
    text-align: left;
    padding: 5px;
}

.abc {
    background-image: url(../img/jiazaizhong.png);
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100vh;
    background-position: center center;
}

.abc2 {
    text-align: center;
}

.end_time {
    text-align: center;
    color: red;
    font-size: 13px;
    margin-top: 10px;
}

.code {
    color: #fff;
    font-size: 14px;
    margin-top: 6px;
}