.ant-col-css {
    display: flex;
    align-items: center;
    margin-top: 5px;
    min-height: 32px;
}

.ant-table-thead>tr>th {
    text-align: center;
}

.ant-table-tbody>tr>td {
    text-align: center;
}